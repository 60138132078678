import React from "react"

import Layout from "../components/layout"
import SeO from "../components/seo"

const TermsConditions = () => (
  <Layout>
    <SeO title="Termes et Conditions" />
    <h1>Termes et Conditions</h1>

    <h2>1. Champs d'application</h2>
    Les présentes Conditions d’utilisation de Video-Lab.io, par Lyketil Sàrl (ci-après "VideoLab") s’appliquent à l’utilisation des applications, services et contenus de VideoLab (ci-après "Services") dont fait usage un client, considéré comme un utilisateur.ice (ci-après «Utilisateur»), comme par exemple:
    
    <ul>
      <li>L’utilisation des différents sites internet et applications mobiles de VideoLab, qu'ils soient gratuits ou payants</li>
      <li>L’utilisation de l'espace membre ou comunautaire de VideoLab</li>
    </ul>

    En utilisant ces Services et/ou leurs fonctionnalités, l’Utilisateur accepte les présentes Conditions d’utilisation dans leur version actuelle respective.
    
    <h2>2. Services de VideoLab</h2>
    <h3>2.1 Généralités</h3>
    <p>VideoLab peut modifier, étendre ou suspendre en tout temps ses Services, tant dans leur forme que dans leur contenu.
    Les descriptions de produits, les offres, les prix et autres indications similaires publiés sur les Services peuvent être modifiés en tout temps par VideoLab et, sauf convention(s) contraire(s) explicite(s) entre VideoLab et l’Utilisateur, ne lient pas ces derniers.</p>
    <p>VideoLab peut choisir de n’offrir certains Services, par exemple la publication de certains contenus, qu’à titre payant sous forme d'abonnement ou de paiement en une ou plusieurs fois. Le paiement de ces contenus ne garantit pas un accès illimité dans le temps si VideoLab devait modifier, suspendre ou modifier ces services.</p>
    <p>VideoLab déploie les efforts appropriés requis afin d’utiliser constamment dans ses Services des données correctes et actualisées, étant précisé que ces dernières peuvent contenir des erreurs ou des informations incomplètes en raison de circonstances techniques ou autres. L’Utilisateur ne peut en déduire aucun droit à l’égard de VideoLab.</p>
    <p>VideoLab s’efforce de garantir un degré élevé de disponibilité des Services proposés, mais décline toute responsabilité quant au fonctionnement ininterrompu et sans défaillance de ses Services. VideoLab s’efforce de réparer, dans les délais appropriés, les pannes et défaillances techniques relevant de sa sphère d’influence.</p>
    <p>L’Utilisateur ne peut se prévaloir envers VideoLab d’aucun droit à l’utilisation des Services.</p>
    
    <h3>2.2 Enregistrement et Compte d’Utilisateur</h3>
    <p>Pour pouvoir utiliser les Services, un enregistrement, resp. la création d’un Compte d‘Utilisateur peut être nécessaire. Seuls les Utilisateurs (qu’il s’agisse de personnes physiques ou morales) qui sont au bénéfice de l’exercice des droits civils sont autorisés à enregistrer ou à ouvrir un Compte d’Utilisateur.</p>

    <p>L’Utilisateur est tenu de fournir des indications complètes et correctes et de procéder immédiatement aux changements nécessaires si ces données personnelles ou de paiement ne sont plus actuelles. L’Utilisateur est seul responsable du contenu de son enregistrement et, par conséquent, seul responsable des informations qu’il met à disposition sur lui-même. VideoLab n’est pas tenu de contrôler le caractère correct ou complet de ces indications.</p>

    <p>Lors de l'enregistrement du compte, le compte utilisateur est créé par VideoLab afin de donner un accès aux contenus et fonctionnalités telles que décrites dans l'offre commerciale. VideoLab n'est pas tenu de transmettre un accès direct de gestion du compte utilisateur (un accès au backoffice de gestion), au delà du service de paiement mis en place sur le site par un service tiers de loging et paiement des abonnements.</p>

    <p>La connexion au Compte d’Utilisateur s’effectue éventuellement (si mis à disposition par VideoLab) au moyen des données d’accès créées lors ou suite à l’enregistrement (p. ex. nom d’utilisateur/mot de passe) ou par le biais d’une plateforme de tiers choisie par l’Utilisateur, resp. par le biais d’un fournisseur d’identité («identity provider»). VideoLab est en droit de considérer tout accès effectué au moyen de données d’accès correctes (nom d’utilisateur/mot de passe), ou tout accès authentifié par un fournisseur d’identité comme un accès dûment autorisé. VideoLab est libre de refuser une connexion pour des raisons légitimes, ou si ce mode de gestion des informations du compte n'est pas choisi par VideoLab.</p>

    <p>VideoLab est libre d’effacer un Compte d’Utilisateur ou de limiter l’accès à ce dernier en tout temps. Ce faisant, des contenus et informations partagés via le Compte d’Utilisateur (dans les espaces communautaires par exemple) peuvent continuer de rester affichés sur les Services de VideoLab ou les plateformes de tiers.</p>

    <p>VideoLab détermine le type de protection d’accès aux Services et prend des mesures afin de protéger ses Services contre des interventions non autorisées de tiers. Toutefois, une protection absolue contre des accès non autorisés de tiers n’existe pas. VideoLab n’est pas tenu, par exemple, de prendre des mesures de protection contre les copies.</p>

    <p>L’Utilisateur ne peut prévaloir d’aucun droit vis-à-vis de VideoLab découlant d’un éventuel abus relatif aux données d’accès ou des éventuels dommages qui lui seraient occasionnés en raison d’un accès abusif à son Compte d’Utilisateur ou résultant de l’utilisation des Services. Si l’Utilisateur constate des abus ou la présence d’offres ou de contenus illicites, ou en présume l’existence, il est tenu d’en informer immédiatement VideoLab.</p>

    <p>Il est interdit à l’Utilisateur de transférer ou de céder à des tiers le Compte d’Utilisateur ou les droits y afférents.</p>
    
    <h2>3. Obligations de l’Utilisateur</h2>
    <p>L’Utilisateur s’engage à utiliser les Services de manière conforme à leur destination et à s’abstenir de toute utilisation non conforme à leur but, abusive ou illicite, soit p. ex. de recourir à des logiciels malveillants (tels que virus, vers («worms») ou autres logiciels d’espionnage).</p>
    
    <p>En outre, il est interdit à l’Utilisateur d’utiliser les Services de manière à affecter leur disponibilité pour VideoLab ou pour d’autres Utilisateurs, ou d’affecter les Services de quelque autre manière (p. ex. en faisant appel à des appareils automatiques, à des scripts, ou à des services de robots, de spiders, de crawlers ou de scrapers).</p>
    
    <p>En particulier, l’Utilisateur s’engage à ne pas publier des Contenus qui enfreignent les droits de la personnalité de tiers, qui contreviennent au droit de la concurrence ou à des obligations de confidentialité, ou qui portent atteinte à l’honneur, resp. des Contenus racistes, incitant à la violence, extrémistes, menaçants, dénigrants ou offensants, pornographiques ou obscènes, ou qui enfreignent des droits de tiers (comme par exemple les droits d’auteur, les droits des marques et les droits liés à la raison sociale).</p>
    
    <p>VideoLab est en droit de bloquer immédiatement et sans préavis, d’effacer, resp. de ne pas publier des Contenus qui contreviennent à ces règles.</p>
    
    <h2>4. Propriété intellectuelle</h2>
    <p>L’Utilisateur reconnaît qu’en règle générale, les Services, les Services de tiers, et les Contenus publiés par VideoLab, des tiers ou d’autres utilisateurs sur lesdits Services sont juridiquement protégés, et qu’une utilisation de ces Services et Contenus nécessite le consentement préalable du détenteur respectif des droits en question.</p>
    
    <p>Sont interdites la reproduction, la transmission (par voie électronique ou par d’autres moyens) ainsi que la modification de tout ou partie des Services ou Contenus. Copier, télécharger ou imprimer les Services ou Contenus n’est autorisé que pour un usage personnel et non commercial, et uniquement dans la mesure où l’usager n’efface aucune mention relative à la propriété intellectuelle, ni d’autres désignations protégées par la loi.</p>
    
    <p>Est interdite l’utilisation, en tout ou partie ou sous forme d’extraits, des contenus mis à disposition.</p>
    
    <h2>5. Responsabilité</h2>
    <p>Toute responsabilité civile de VideoLab vis-à-vis des Utilisateurs en lien avec l’utilisation des Services est exclue dans la mesure autorisée par la loi. En particulier, l’Utilisateur prend acte du fait que VideoLab ne répond pas des Contenus publiés par d’autres utilisateurs sur les espaces communautaires et commentaires de VideoLab.</p>
    
    <p>L’Utilisateur s’engage à libérer intégralement VideoLab de toute prétention en responsabilité formulée par des tiers imputable au non-respect des présentes Conditions d’utilisation.</p>
    
    <h2>6. Non-respect des Conditions d'Utilisation</h2>
    En cas de non-respect des présentes Conditions d’utilisation, VideoLab est en droit de bloquer, resp. de refuser à un Utilisateur l’accès aux Services indiqués.
    
    <h2>7. Dispositions finales</h2>
    <p>VideoLab se réserve le droit de modifier les présentes Conditions d’utilisation autant que de besoin. La version actuelle respective consultable sous ce site fait foi. Si une ou plusieurs dispositions sont nulles ou sans effet, la validité des autres dispositions n'en est pas affectée.</p>
    
    <p>Le droit suisse est applicable aux présentes Conditions d’utilisation, à l’exclusion des règles du droit international privé. Le for exclusif pour tout litige résultant des présentes Conditions d’utilisation ou lié à ces dernières est le siège de Lyketil Sàrl : Lausanne. Lyketil Sàrl, 2/2021</p>

  </Layout>
)

export default TermsConditions
